import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { LoadDebitTypesAsOptions } from "src/app/shared/states/prefill-data/prefill-data.actions";
import { getUpdatedItems } from "../../../../shared/utils/utils";
import { AdminPagesStateDefaults, SetError } from "../../admin.state";
import { DebitTypesService } from "../debit-types.service";
import { AddDebitType, LoadDebitTypes, RemoveDebitType, UpdateDebitType } from "./debit-types.actions";
import { DebitTypesStateModel } from "./debit-types.model";

@Injectable()
@State<DebitTypesStateModel>({
  name: "debitTypes",
  defaults: AdminPagesStateDefaults
})
export class DebitTypesState {
  constructor(
    private debitTypesService: DebitTypesService
  ) {}

  @Selector() static debitTypes(state: DebitTypesStateModel) { return state.items; }
  @Selector() static loading(state: DebitTypesStateModel) { return state.loading; }
  @Selector() static saving(state: DebitTypesStateModel) { return state.saving; }

  @Action(LoadDebitTypes)
  load(ctx: StateContext<DebitTypesStateModel>) {
    ctx.patchState({
      items: [],
      loading: true
    });

    return this.debitTypesService.getDebitTypes().pipe(
      tap(
        (response) => {
          ctx.patchState({
            items: response.items,
            loaded: true,
            loading: false
          });
          ctx.dispatch(new LoadDebitTypesAsOptions(response.items));
        },
        (error) => {
          ctx.patchState({ items: [], loading: false });
          ctx.dispatch(new SetError({ loading: error }));
        }
      )
    );
  }

  @Action(AddDebitType)
  addDebitType(ctx: StateContext<DebitTypesStateModel>, action: AddDebitType) {
    ctx.patchState({ saving: true });
    
    return this.debitTypesService.createDebitType(action.payload).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: [response, ...state.items],
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(UpdateDebitType)
  updateDebitType(ctx: StateContext<DebitTypesStateModel>, action: UpdateDebitType) {
    ctx.patchState({ saving: true });
    return this.debitTypesService.updateDebitType(action.payload).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        ctx.patchState({
          items: getUpdatedItems(response, state.items),
          saving: false
        });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ saving: false });
      }
    );
  }

  @Action(RemoveDebitType)
  removeDebitType(ctx: StateContext<DebitTypesStateModel>, action: RemoveDebitType) {
    return this.debitTypesService.removeDebitType(action.debitTypeId).then(
      (response) => {
        action.callbackSuccess();
        const state = ctx.getState();
        const updated = state.items.filter(item => {
          return item.id !== action.debitTypeId;
        });
        ctx.patchState({ items: updated });
      },
      (error) => {
        action.callbackError();
        ctx.patchState({ loading: false });
      }
    );
  }
}
