import { DebitType } from "../interfaces/debit-type.interface";

export class LoadDebitTypes {
  public static readonly type = "[Debit Types] load debit types";
}

export class AddDebitType {
  public static readonly type = "[Debit Types] add debit type";
  constructor(
    public payload: DebitType,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class UpdateDebitType {
  public static readonly type = "[Debit Types] update debit type";
  constructor(
    public payload: DebitType,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}

export class RemoveDebitType {
  public static readonly type = "[Debit Types] remove debit type";
  constructor(
    public debitTypeId: string,
    public callbackSuccess: () => void,
    public callbackError: () => void
  ) {}
}
