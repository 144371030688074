import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { API_URL } from "../../../../environments/settings";
import { EnvironmentService } from "../../../core/services/environment.service";
import { DebitType } from "./interfaces/debit-type.interface";

@Injectable({ providedIn: "root" })
export class DebitTypesService {

  private apiUrl: string;

  constructor(
    private http: HttpClient,
    private env: EnvironmentService
  ) {
    this.apiUrl = `${this.env.getValueTransferState(API_URL)}/app/debitType`;
  }

  getDebitTypes() {
    return this.http.get<any>(this.apiUrl);
  }

  updateDebitType(debitType: DebitType) {
    return this.http.put<any>(`${this.apiUrl}/${debitType.id}`, debitType).toPromise();
  }

  createDebitType(debitType: DebitType) {
    return this.http.post<any>(`${this.apiUrl}`, debitType).toPromise();
  }

  removeDebitType(debitTypeId: string) {    
    return this.http.delete(`${this.apiUrl}/${debitTypeId}`).toPromise();
  }
}
